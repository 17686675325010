import React from 'react';
import {urlMap, _base as baseUrl, _base} from '../../../constants/urlMap';

function Menu() {
    return (
        <ul className="navbar" >
            <li>
                <a href={urlMap.home} className='responsive-menu-list-item'>
                    Home
                </a>
            </li>

            <li>
                <a href={urlMap.contact} className='responsive-menu-list-item'>
                    Contact
                </a>
            </li>
            <li>
                <a href={_base} className='responsive-menu-list-item'>
                    Westpoint
                </a>
            </li>
            
        </ul>
    );
}

export default Menu;