import React from 'react';
import './ArticleItem.scss';

function ArticleItem({ 
    title, 
    description, 
    category, 
    timetoread,
    image, 
    date,
    id,
    display
}) {

    const customDescription = () => {
          if(description.length > 155){
              return description.substr(0, 155) + `...`;
          }
          return description;
    };

    return (
        <div className="article-item" style={{
            display: !display && 'none'
        }}>
            <div className={`article-item__info`}>
                <a 
                    href={`/article/${id}`}
                    className="title"
                >
                    {title}
                </a>
                <div className="sub-description-div">
                    <div className="date-div">
                        <span>{date}</span>
                    </div>
                    <div className="category-div">
                        <span className="category-span">{category}</span>
                    </div>
                    <div className="timeToRead-div">
                        <span>{timetoread} min read</span>
                    </div>
                </div>
                <div className="description-div">
                    <p className="description">{customDescription()}</p>
                </div>
            </div>
            <div>
                <img src={image.url} alt={image.alt}/>
            </div>
        </div>
    );
}

export default ArticleItem;