import React from 'react';
import ReactDOM from 'react-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./global.scss"

import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
