import React from 'react';
import Loader from 'react-loader-spinner';

function AbsoluteLoading() {
    return (
        <div className={`absolute-loading`}>
            <Loader
                type="BallTriangle"
                color="#f9fafa"
                style={{marginTop: 100, marginBottom: 100}}
            />
        </div>
    )
}

export default AbsoluteLoading;