import React from 'react';
import Navbar from '../../components/organisms/Navbar';
import './Page.scss';
import Footer from '../../components/organisms/Footer/Footer';

function Page({ children }) {
  return (
    <div className="page">
      <Navbar />
      {children}

      <Footer />
    </div>
  );
}

export default Page;
