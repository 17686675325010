import React, {useContext, useRef} from 'react';
import InfiniteScroll from "react-infinite-scroller";
import { ArticlesContext } from "../../../pages/Main/Main";
import ArticleItem from "../../molecules/ArticleItem";
import './Articles.scss';
import Skeleton from "react-loading-skeleton/lib";

function Articles() {

    const scrollRef = useRef(null);
    const articles = useContext(ArticlesContext);

    return (
        <div className={'articles'}>
            <h3>Articles</h3>
            <div ref={scrollRef} className={`post-list`}>

                {articles.length > 0 && (
                    <InfiniteScroll
                        pageStart={0}
                        useWindow={false}
                        getScrollParent={() => scrollRef.current}
                        loadMore={(e) => console.log(e)}
                        // hasMore={posts.filter(p => p.obj.display).length !== articles.length}
                        // loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                        {articles.map((article, i) => (
                            <ArticleItem {...article} display={i < 5} />
                        ))}
                    </InfiniteScroll>
                )}

                {articles.length === 0 && (
                    <Skeleton
                        count={300}
                    />
                )}

            </div>
        </div>
    )
}

export default Articles;