import React, {useContext, useEffect, useState} from 'react';
import Slider from 'react-slick';
import settings from './settings';
import FeaturedArticle from "../../molecules/FeaturedArticle";
import {ArticlesContext} from "../../../pages/Main/Main";
import './FeaturedArticles.scss';
import AbsoluteLoading from "../../atoms/AbsoluteLoader";

function FeaturedArticles() {

    const [featuredArticles, setFeaturedArticles] = useState([]);

    const articles = useContext(ArticlesContext);

    useEffect(() => {
        if(featuredArticles.length > 0) return;
        const _ = articles.filter( a =>  a.featured === true);

        setFeaturedArticles(_.map(fa => (
            <FeaturedArticle
                {...fa}
            />
        )));

    }, [articles])

    return (
        <div className='featured-articles'>
            {featuredArticles.length === 0 && (
                <AbsoluteLoading />
            )}
            {featuredArticles.length > 0 && (
                <Slider {...settings}>
                    {featuredArticles}
                </Slider>
            )}
        </div>
    );
}

export default FeaturedArticles;