const settings = {
    className: "carousel-custom",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
        {
            breakpoint: 770,
            settings: {
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true,
                speed: 900,
                slidesToShow: 2,
                className: "carousel-custom",
                slidesToScroll: 2,
                centerMode: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true,
                speed: 900,
                slidesToShow: 1,
                className: "carousel-custom",
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "1%",
            }
        }
    ]
};

export default settings;