import React, {useContext, useEffect, useState} from 'react';
import {ArticlesContext} from "../../../pages/Main/Main";
import Category from "../../atoms/Category";
import './Categories.scss'
import Skeleton from "react-loading-skeleton/lib";

function Categories({ onFilter, clearFilter }){

    const [ categories, setCategories ] = useState([]);
    const [ activeOne, setActiveOne ] = useState(false);
    const articles = useContext(ArticlesContext);

    useEffect(() => {
        if(categories.length) return;

        const cats = [];

        articles.forEach((article) => {
            if(cats.indexOf(article.category) === -1){
                cats.push(article.category);
            }
        });

        setCategories(cats);

    }, [articles])

    return (
        <div className="right-section">
            <div className="section-title">
                <h3>Categories</h3>
            </div>
            <div className="categories-section">
                {categories.length > 0 && categories.map((category) => {
                    return (
                        <Category
                            category={category}
                            onClick={(category) => {
                                clearFilter();
                                onFilter(category);
                                setActiveOne(category);
                            }}
                            activity={activeOne}
                        />
                    )
                })}

                {categories.length === 0 && (
                    <Skeleton
                        count={6}
                    />
                )}

                <Category category={`Clear`} onClick={clearFilter} activity={false}/>
            </div>
        </div>
    )

}

export default Categories;