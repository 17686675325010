import React, { useState, useEffect, useRef } from 'react';
import Page from '../../templates/Page/Page';
import Slider from 'react-slick';
import FeaturedArticle from '../../components/molecules/FeaturedArticle/FeaturedArticle';
import './Main.scss';
import InfiniteScroll from 'react-infinite-scroller';
import ArticleItem from '../../components/molecules/ArticleItem/ArticleItem';
import _ from 'lodash';
import Api from '../../services/Api';
import FeaturedArticles from "../../components/organisms/FeaturedArticles";
import Articles from "../../components/organisms/Articles";
import Categories from "../../components/organisms/Categories";

export const ArticlesContext = React.createContext({ articles: []});

function Main() {

    const [ articles, setArticles ] = useState([]);
    const [ posts, setPosts ] = useState([]);
    const [ _articles, setFilteredArticles ] = useState([]);
    const [ pages, setPages] = useState(1);

    useEffect(() => {

        if(posts.length === 0){
            Api.get(`posts`).then(({ data }) => {
                const articles = data.item;
                setArticles(articles);
                console.log('request')
                setPosts(articles);
            });
        }

    }, []);

    const filter = (category) => {

        const _ = _articles.length > 0 ? posts : articles;

        const filtered = _.filter(p => {
            return p.category === category;
        });
        setFilteredArticles(filtered)

        setArticles(filtered);
    }

    const clearFilter = () => {
        setArticles(posts);
    }

    return (
        <Page>
            <ArticlesContext.Provider value={articles}>
                <div className="row">
                    <FeaturedArticles />
                </div>
                <div className="row start posts">
                    <Articles />
                    <Categories onFilter={filter} clearFilter={clearFilter}/>
                </div>
            </ArticlesContext.Provider>


        </Page>
    );
}

export default Main;