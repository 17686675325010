export const mockedArticle = {
    id: 'test',
    title: 'The brilliant and helpful thing that is a lorem ipsum and how it can help you to diagram',
    content: '### Lorem Ipsum \n Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    date: '2020-04-22',
    metaDescription: 'Lorem ipsum is a thing usefull on tests',
    timeToRead: 5,
    category: 'Technology',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at urna fringilla, semper felis in, viverra nibh.',
    image: {
        url: 'https://image.freepik.com/fotos-gratis/bela-vista-da-cidade-de-uma-cidade-urbana-um-tiro-de-cima_181624-406.jpg',
        alt: 'Image test'
    }
};

export const mockedFooterLinks = [
    {
      title: "Site links",
      links: [
        {
          "name": "Home",
          "path": "/" 
        },
        {
          "name": "Services",
          "path": "/" 
        },
        {
          "name": "About us",
          "path": "/" 
        },
        {
          "name": "Contact",
          "path": "/" 
        },
        {
          "name": "Blog",
          "path": "/" 
        }
      ],
    },
    {
      title: "Company information",
      links: [
        {
          "name": "About us",
          "path": "/" 
        },
        {
          "name": "Team",
          "path": "/" 
        },
        {
          "name": "Technologies",
          "path": "/" 
        }
      ],
    },
    {
      title: "Case studies",
      links: [
        {
          "name": "Belron",
          "path": "/" 
        },
        {
          "name": "Toyota",
          "path": "/" 
        },
        {
          "name": "Class for Kids",
          "path": "/" 
        }
      ],
    },
    {
      title: "Services",
      links: [
        {
          "name": "Overview",
          "path": "/" 
        },
        {
          "name": "Consulting",
          "path": "/" 
        },
        {
          "name": "Full project",
          "path": "/" 
        },
        {
          "name": "POC",
          "path": "/" 
        }
      ],
    },
    {
      title: "Blog",
      links: [
        {
          "name": "Some link",
          "path": "/" 
        },
        {
          "name": "Some link",
          "path": "/" 
        },
      ],
    },
    {
      title: "London office",
      links: [
        {
          "name": "Westpoint Software Solutions",
          "path": "/" 
        },
        {
          "name": "100 Canon Street",
          "path": "/" 
        },
        {
          "name": "EC4N 6EU",
          "path": "/" 
        },
        {
          "name": "+44 0203 488 5406",
          "path": "/" 
        },
        {
          "name": "leo@westpoint.io",
          "path": "/" 
        },
      ],
    },
];

export const mockedFeaturedArticles = [
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut",
      category: "Technology",
      image: "https://image.freepik.com/fotos-gratis/bela-vista-da-cidade-de-uma-cidade-urbana-um-tiro-de-cima_181624-406.jpg",
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut",
      category: "Technology",
      image: "https://image.freepik.com/fotos-gratis/bela-vista-da-cidade-de-uma-cidade-urbana-um-tiro-de-cima_181624-406.jpg",
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut",
      category: "Technology",
      image: "https://image.freepik.com/fotos-gratis/bela-vista-da-cidade-de-uma-cidade-urbana-um-tiro-de-cima_181624-406.jpg",
    }
];