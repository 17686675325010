import React from 'react';
import './FeaturedArticle.scss';

function FeaturedArticle({ image, category, title, id}) {

    return (
        <a href={`/article/${id}`}>
            <div className="featured-article">
                <div className="featured-article__img">
                    <img src={image.url} alt={image.alt}/>
                </div>
                <span className="featured-article__category">{category}</span>
                <a className="featured-article__title" href={`/article/${id}`}>{title}</a>
            </div>
        </a>
    );
}

export default FeaturedArticle;