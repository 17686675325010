import React, {useEffect, useState} from 'react';
import './Category.scss';

function Category({
    category,
    onClick,
    activity
}) {
    const [active, setActive] = useState(category === activity);

    return (
        <button
            className={`button button--full button--${active?'blue':'orange'}`}
            onClick={() => {
                onClick(category);
            }}

        >
            {category}
        </button>
    )
}

export default Category;