import React, { useEffect, useState } from 'react';
import { mockedFooterLinks } from '../../../../mock';
import './Footer.scss';

function Footer() {
    const [links, setLinks] = useState([]);
  
    useEffect(() => {
        setLinks(mockedFooterLinks);
    },[])
  
    return(
      <div className="footer">
        <div className="footer-div-flex">
            {links.map((link) => {
                return(
                    <div
                        className="footer-div-information"
                        align="left"
                    >
                        <div>
                            <span className="orange footer-selection-title">{link.title}</span>
                        </div>
                        <div>
                            <ul className="footer-ul">
                            {link.links.map((element) => {
                                return <li><a href={element.path}>{element.name}</a></li>
                            })}  
                            </ul>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="footer-copyright-div">
            <span>&copy; 2020 Westpoint</span>
        </div>
      </div>
    );
}
  
export default Footer;