
export const _base = 'https://westpoint.io/';

export const urlMap = {
    home: `/`,
    worldOfServerless: `${_base}world-of-serverless`,
    company: {
        about: `${_base}company/about`,
        team: `${_base}company/our-team`,
        technologies: `${_base}company/technologies`,
        caseStudies: {
            belron: `${_base}company/case-studies/belron`,
            toyota: `${_base}company/case-studies/toyota`,
            classForKids: `${_base}company/case-studies/class-for-kids`,
        }
    },
    services: {
        overview: `${_base}services/overview`,
        fullProject: `${_base}services/full-project`,
        poc: `${_base}services/poc`,
        consultation: `${_base}services/consultation`
    },
    aws: {
        partner: `${_base}aws/partner`,
        architecture: `${_base}aws/architecture`,
        lambda: `${_base}aws/lambda`
    },
    contact: `${_base}contact`,
    blog: `https://www.google.com`
}


export default urlMap;