import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './lib/pages/Main/Main';
import Article from './lib/pages/Article';

function App() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/article/:articleId" render={(props)=> {
            return <Article {...props} featuredArticles={[]} />
          }}/>
        </Switch>
      </Router>
      
    );
}

export default App;
