import React from 'react';
import { _base } from '../../../constants/urlMap';
import './Navbar.scss';
import Menu from './Menu';


function Navbar() {
    return (
        <>
            <div className="top">
                <nav className="topbar">
                    <div className="logo">
                        <img 
                            src={`${_base}imgs/logos/Westpoint_Logo_White_Small.png`} 
                            alt="Westpoint Logo"
                        />
                    </div>
                    <Menu />
                </nav>
            </div>
            <div className="top-responsive">
                <nav className="topbar-responsive">
                    <div className="logo">
                        <img 
                            src={`${_base}imgs/logos/Westpoint_Logo_White_Small.png`} 
                            alt="Westpoint Logo"
                        />
                    </div>
                    <button
                        id="openNav"
                        className="toggle-menu"
                        style={{
                            padding: 0,
                            height: 0,
                            borderWidth: 0
                        }}
                    >
                        <span></span>
                    </button>
                    <div className="collapsed-menu">
                        <Menu />
                    </div>
                </nav>

            </div>
        </>
    )
}

export default Navbar; 