import React, {useState, useEffect, useContext} from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import Page from '../../templates/Page/Page';
import FeaturedArticle from '../../components/molecules/FeaturedArticle/FeaturedArticle';
import Api from '../../services/Api';
import Skeleton from "react-loading-skeleton/lib";
import AbsoluteLoading from "../../components/atoms/AbsoluteLoader";
import './Article.scss';

function Article({ match }) {

    const [article, setArticle] = useState();
    const [featuredArticles, setFeaturedArticles] = useState([]);

    useEffect(() =>{
        const { articleId } = match.params;
        Api.get(`posts/${articleId}`).then(({ data }) => {

            const article = data.item;
            
            setArticle(article);

            Api.get('posts').then(({data}) => setFeaturedArticles(data.item));
        });
    }, []);

    return (
        <Page>
                <div className='article'>
                    { article && (
                        <Helmet>
                            <title>{article.title}</title>
                            <meta name="description" content={article.metaDescription} />
                        </Helmet>
                    )}
                    <div className="article__header">
                        {article && (
                            <>
                                <div className="article__header__wrapper">

                                    <h1>{article.title}</h1>

                                    <div className={`additional-info`}>
                                        <span>{article.date}</span>
                                        <span>{article.timetoread} min read</span>
                                    </div>
                                </div>
                                <img src={article.image.url} alt={article.image.alt}/>
                            </>
                        )}
                        {!article && (
                            <div style={{marginLeft: '50vw'}}>
                                <AbsoluteLoading />
                            </div>
                        )}
                    </div>
                    <div className="article__body">
                        {article && (
                            <ReactMarkdown>{article.content}</ReactMarkdown>
                        )}

                        {!article && (
                            <Skeleton count={400} />
                        )}
                    </div>

                    <div className='article__featured-articles'>
                        <h3 className='article__featured-articles__title'>
                            Check out our featured articles
                        </h3>
                        {featuredArticles && (
                            <div className='article__featured-articles__list'>
                                {
                                    featuredArticles
                                        .filter((featuredArticle) => {
                                            return featuredArticle.description !== article.description
                                        })
                                        .filter((featuredArticle, index) => {
                                            return index <= 2
                                        })
                                        .map((featuredArticle) => (
                                            <FeaturedArticle {...featuredArticle} />
                                        ))
                                }
                            </div>
                        )}
                    </div>
                </div>
        </Page>
    );
    }


export default Article;